<template>
  <div class="share_verify_pwd">
    <img class="logo" src="" alt="" />
    <el-input size="medium" v-model="password" placeholder="请输入访问密码" clearable>
      <el-button slot="append" @click="handleClick">点击查看</el-button>
    </el-input>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      password: ''
    };
  },
  created() {},
  methods: {
    handleClick() {
      if (!this.password) return this.$alert('请输入访问密码');

      let routeData = this.$router.resolve({ path: '/share-settlement-list', query: { id: 1 } });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped lang="scss">
.share_verify_pwd {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, #fff, #1890ff, #1890ff, #1890ff, #fff);

  .logo {
    width: 200px;
    text-align: center;
  }
  .el-input {
    margin-top: 30px;
    width: 360px;
  }
}
</style>
